<template>
  <div>
    <el-progress v-if="progress > 0"
                 style="width: 300px;margin-bottom:10px;"
                 status="success"
                 :text-inside="true"
                 :stroke-width="20"
                 :percentage="progress"></el-progress>
    <el-upload :action="dataObj.host"
               :data="dataObj"
               list-type="picture"
               :accept="accept"
               :multiple="false"
               :show-file-list="false"
               :file-list="fileList"
               :before-upload="beforeUpload"
               :on-remove="handleRemove"
               :on-success="handleUploadSuccess"
               :on-progress="handleUploadProgress"
               :on-preview="handlePreview">
      <!-- <el-button size="small"
                 type="primary"
                 style="float: right;">上传文件
      </el-button> -->
      {{title}}
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <div>{{fileList[0].name}}</div>
    </el-dialog>
  </div>
</template>
<script>
import ossApi from '@/api/apiModel/upload'

export default {
  name: 'fileUpload',
  props: {
    value: String,
    showList: {
      type: Boolean,
      default: true
    },
    accept: {
      type: String,
      default: '.xlsx,.xls,.doc,.docx,.pdf,.zip,.rar,application/x-rar-compressed,application/zip,application/x-zip-compressed,application/octet-stream'
    },
    title: String,
  },
  computed: {
    imageUrl () {
      return this.value
    },
    imageName () {
      if (this.value != null && this.value !== '') {
        return this.value.substr(this.value.lastIndexOf('/') + 1)
      } else {
        return null
      }
    },
    fileList () {
      return [{
        name: this.imageName,
        url: this.imageUrl
      }]
    },
    showFileList: {
      get: function () {
        return this.value !== null && this.value !== '' && this.value !== undefined && this.showList
      },
      set: function (newValue) {
      }
    }
  },
  data () {
    return {
      progress: 0,
      dataObj: {
        policy: '',
        signature: '',
        key: '',
        ossaccessKeyId: '',
        dir: '',
        host: 'http://ecrent.oss-cn-beijing.aliyuncs.com'
      },
      dialogVisible: false
    }
  },
  methods: {
    emitInput (val) {
      this.$emit('input', val)
    },
    handleRemove (file, fileList) {
      this.emitInput('')
    },
    handlePreview (file) {
      this.dialogVisible = true
    },
    beforeUpload (file) {
      let _self = this
      // 最大只能上传200M文件
      if (file.size > 209715200) {
        this.$message.error('上传失败，最大只能上传200M大小的压缩文件')
        return false
      }
      return new Promise((resolve, reject) => {
        ossApi.policy().then(response => {
          _self.dataObj.policy = response.result.policy
          _self.dataObj.signature = response.result.signature
          _self.dataObj.ossaccessKeyId = response.result.accessKeyId
          _self.dataObj.key = response.result.dir + '/' + file.uid + file.name
          _self.dataObj.dir = response.result.dir
          _self.dataObj.host = response.result.host
          resolve(true)
        }).catch(err => {
          console.log(err)
          reject(false)
        })
      })
    },
    handleUploadProgress (e, file, fileList) {
      this.progress = e.percent
    },
    handleUploadSuccess (res, file) {
      this.progress = 0
      this.showFileList = this.showList
      this.fileList.pop()
      this.fileList.push({
        name: file.uid + file.name,
        url: this.dataObj.host + '/' + this.dataObj.dir + '/' + file.uid + file.name
      })
      this.emitInput(this.fileList[0].url)
      let obj = {
        url:this.fileList[0].url,
        appendixName:file.name,
        appendixSize:file.size,
        appendixType: 0
        // appendixType: file.name.replace(/.+\./, "")
      }
      this.$emit('emitEmailInput',obj)
    }
  }
}
</script>
<style scoped>
</style>

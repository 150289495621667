<template>
  <div class="chat-window">
    <div class="chat-title">
      <p v-if="type == 1">收件箱-展开邮件-转发</p>
      <p v-else>收件箱-展开邮件-回复</p>
    </div>
    <div class="chat-container">
      <div class="top-button">
        <el-button round
                   size="small"
                   icon="el-icon-d-arrow-left"
                   @click="$router.back()"></el-button>
        <el-button round
                   size="small"
                   @click="handleSend">发送</el-button>
      </div>
      <div class="inbox-content">
        <el-form :model="model"
                 size="small"
                 label-width="60px"
                 class="content">
          <el-form-item label="发件人">
            <el-input v-model="model.name"
                      class="input-style"></el-input>
          </el-form-item>
          <el-form-item label="收件人">
            <el-input v-model="model.remark"
                      class="input-style"></el-input>
          </el-form-item>
          <el-form-item label="主题">
            <label slot="label">主&nbsp;&nbsp;&nbsp;&nbsp;题</label>
            <el-input v-model="model.remark"
                      class="input-style"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="item">
              <img src="@/assets/images/ic_fujian1@3x.png">
              <file-upload title="文档附件"></file-upload>
            </div>
            <div class="item">
              <img src="@/assets/images/ic_fujian2@3x.png">
              <single-upload :showList="false"
                             title="图片附件"></single-upload>
            </div>
            <div class="item">
              <img src="@/assets/images/ic_fujian3@3x.png">
              <file-upload title="超大附件"></file-upload>
            </div>
          </el-form-item>
          <el-form-item label="正文">
            <el-input v-model="model.remark"
                      type="textarea"
                      :rows="10"
                      resize="none"
                      class="input-style"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="submit">
        <div class="submit-button">
          <el-button round
                     size="small"
                     icon="el-icon-d-arrow-left"
                     @click="$router.back()"></el-button>
          <el-button round
                     size="small"
                     @click="handleSend">发送</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleUpload from '@/components/Upload/singleUpload'
import fileUpload from '@/components/Upload/fileUpload'

export default {
  // 收件详情回复
  name: 'InboxDetailReply',
  components: {
    SingleUpload,
    fileUpload
  },
  data () {
    return {
      model: {},
      type: null,
    }
  },
  created () {
    this.type = this.$route.query.type
  },
  methods: {
    // 发送
    handleSend () {

    },
  }
}
</script>

<style scoped>
.chat-window {
  min-width: 506px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-left: 1px solid #dcdfe6;
}
.chat-window .chat-title {
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 18px;
  color: #313233;
  letter-spacing: 2px;
  padding: 0 20px;
  background: #ffffff;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
}
.chat-window .chat-container {
  height: 540px;
  width: 100%;
  /* padding-top: 20px; */
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* overflow-x: hidden;
  overflow-y: auto; */
  /* position: relative; */
}
.top-button {
  height: 47px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  border-bottom: 1px solid #dcdfe6;
}
.top-button button {
  width: 56px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.top-button button:nth-child(1) {
  width: 48px;
}
.inbox-content {
  /* height: 100%; */
  height: 424px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
}
.content .el-form-item:nth-child(4) >>> .el-form-item__content {
  display: flex;
  flex-direction: row;
}
.inbox-content .content .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
}
.content .item img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.content .item p {
  font-size: 12px;
  color: #7b7d80;
}
.content .item >>> .el-upload {
  font-size: 12px;
  color: #7b7d80;
}
.submit {
  width: 100%;
  height: 68px;
  padding-left: 10px;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* position: absolute;
  bottom: 0; */
  border-top: 1px solid #dcdfe6;
}
.submit-button {
  display: flex;
  flex-direction: row;
}
.submit button {
  width: 56px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.submit button:nth-child(1) {
  width: 48px;
}
</style>